/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Podéis ver en mi barra del blog un nuevo widget, Mi Feevy, feevy es un blogroll dinámico, con el se visualizan los ultimos posts de los blogs que tu quieras, además se le pueden añadir imagenes y videos. La lastima es que al tener mi blog en wordpress.com, solo lo puedo añadir con RSS y no queda tan bonito como insertado con html. Pero el concepto es el mismo. Es muy sencillo de utilizar, solo teneis que abriros una cuenta en ", React.createElement(_components.a, {
    href: "http://www.feevy.com/"
  }, "www.feevy.com"), " y seguir unos sencillos pasos."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
